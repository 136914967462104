import React, { useEffect, useState } from 'react'
import Layout from '../containers/Layout'
import { ArrowSmLeftIcon } from '@heroicons/react/outline'
import { useQueryParam, BooleanParam } from 'use-query-params'
import { doc, getDoc } from 'firebase/firestore'
import { navigate } from 'gatsby'
import { format } from 'date-fns'
import { db } from '../utils/firebase'
import Iframe from '../common/ui/Iframe'

const Email = ({ id }) => {
  const [isPlain, setIsPlain] = useQueryParam('plain', BooleanParam)
  const [emailData, setEmailData] = useState(null)
  const [senderData, setSenderData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const asyncInit = async () => {
      setLoading(true)
      const emailSnap = await getDoc(doc(db, 'emails', id))
      const senderSnap = await getDoc(
        doc(db, 'senders', emailSnap.data().sender_ref.id)
      )
      setEmailData(emailSnap.data())
      setSenderData(senderSnap.data())
      setLoading(false)
    }
    asyncInit()
  }, [id])

  return (
    <Layout>
      <section className='py-4'>
        <div className='container !p-0 !px-2'>
          <a
            href='!#'
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
            className='flex gap-4 text-xs no-underline label group'
          >
            <ArrowSmLeftIcon className='inline w-5 mb-1 text-gray-400 transition group-hover:-translate-x-1 group-hover:text-blue-400' />
            Return to Results
          </a>
        </div>
      </section>
      <section className='py-0'>
        <div className='container !p-4 bg-primary flex justify-between'>
          {senderData && (
            <>
              <p>
                {senderData.source === 'orgs'
                  ? 'From the Organization '
                  : 'From the Campaign '}
                <a
                  href={`/sender/?size=n_20_n&s=${
                    senderData && senderData.name
                  }`}
                  className='text-blue-500 no-underline hover:text-dark hover:underline'
                >
                  {senderData && senderData.name}
                </a>
              </p>
              <p className='text-right'>
                {senderData && senderData.source === 'orgs'
                  ? senderData.type
                  : senderData.office_sought}
              </p>
            </>
          )}
        </div>
        <div className='container bg-light'>
          {!loading && (
            <h1 className='capitalize'>
              {emailData && emailData.subject
                ? emailData.subject
                : '[[EMAIL_REDACTED]]'}
            </h1>
          )}
          <div className='grid grid-cols-3 gap-2 border-b-2 border-red-400'>
            <p className='col-span-2 capitalize'>
              <strong>From Name:</strong> {emailData && emailData.from[0].name}
            </p>
            <p className='col-span-2 capitalize'>
              <strong>From Address:</strong>{' '}
              {emailData && emailData.from[0].address}
            </p>
            <div className='!pb-4 flex justify-end'>
              <p className='label'>{emailData && emailData.from[0].email}</p>
              <p className='label'>
                {emailData &&
                  format(
                    new Date(emailData.date.toDate()),
                    'd-MMM-yyyy @ hh:mm'
                  )}
              </p>
            </div>
          </div>
          <a
            href='!#'
            className='mt-4 underline cursor-pointer label'
            onClick={(e) => {
              e.preventDefault()
              setIsPlain(0)
            }}
          >
            html
          </a>{' '}
          |{' '}
          <a
            href='!#'
            className='mt-4 underline cursor-pointer label'
            onClick={(e) => {
              e.preventDefault()
              setIsPlain(1)
            }}
          >
            plaintext
          </a>
        </div>
      </section>
      <section className='py-0 bg-dark'>
        <div className='container !pt-0 mt-0 bg-light'>
          <div className='p-8 bg-white'>
            <div className={`${!isPlain ? '' : 'max-w-xl mx-auto'}`}>
              {!isPlain
                  ? emailData && <Iframe content={emailData.text_html} />
                  : emailData &&
                  emailData.text_plain
                }
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Email
