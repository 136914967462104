import React, { useEffect, useRef } from "react";

// iframe.contentWindow.document.body.scrollHeight

const Iframe = ({ content }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const writeHTML = () => {
      if (!iframe) {
        return;
      }
      const doc = iframe.contentDocument;
      doc.open();
      doc.write(content);
      doc.close();
      // const width = iframe.parentNode.offsetWidth;
      const height = doc.body.scrollHeight;

      iframe.style.width = '100%';
      iframe.style.height = `${height}px`;
    };
    writeHTML();
  }, [content]);

  return (
    <iframe
      title="email"
      src="about:blank"
      scrolling="yes"
      frameBorder="0"
      ref={iframeRef}
    />
  );
};

export default Iframe;

